import request from '@/utils/request'

/**
 * 根据订单ID获取订单信息
 *
 * 本函数通过发送GET请求到指定的URL，来获取指定订单的详细信息。
 * 使用者需要提供订单的ID作为参数，函数将返回请求的结果。
 *
 * @param {string} orderId - 订单的唯一标识ID
 * @returns {Promise} 返回一个Promise对象，包含服务器响应的数据
 */
export function orderInfo(orderId) {
    return request({
        url: `/open/order/info/${orderId}`,
        method: 'GET',
    })
}

/**
 * 发起订单支付
 *
 * 本函数通过发送POST请求到支付接口，来发起订单的支付操作。
 * 使用者需要提供订单的相关支付信息作为参数，函数将返回请求的结果。
 *
 * @param {object} data - 包含订单支付信息的对象
 * @param {number} data.orderId - 需要支付的订单ID
 * @param {number} data.clientType - 客户端类型
 * @param {string} data.returnUrl - 返回页面
 * @returns {Promise} 返回一个Promise对象，包含服务器响应的数据
 */
export function orderPay(data) {
    return request({
        url: '/open/order/aliPay',
        method: 'POST',
        data
    })
}

/**
 * 订单支付结果查询
 *
 * 本函数通过发送GET请求到支付接口，来查询订单的支付结果。
 * 使用者需要提供订单的ID作为参数，函数将返回请求的结果。
 *
 * @param {string} orderNo - 订单的唯一编号
 * @returns {Promise} 返回一个Promise对象，包含服务器响应的数据
 */
export function orderPayResult(orderNo) {
    return request({
        url: `/open/order/queryPayResult/${orderNo}`,
        method: 'GET',
    })
}

/**
 * 获取订单列表的接口调用函数
 *
 * 该函数用于向服务器发送请求，获取指定条件下的订单列表数据。
 * 主要用于前端页面展示订单信息，或进行相关业务操作时的数据获取。
 *
 * @param {Object} params 请求参数对象，用于指定查询订单的条件。
 * @returns {Promise} 返回一个Promise对象，包含服务器响应的数据。
 */
export function orderList(params) {
    // 发送GET请求，获取订单列表数据
    return request({
        url: '/open/order/deiveList',
        method: 'GET',
        params
    })
}

/**
 * 根据订单号获取驾驶订单信息
 *
 * 本函数通过发送GET请求到指定的URL，来获取特定驾驶订单的详细信息。
 * 它使用了订单号作为请求资源的标识符，以便服务器能够准确地返回相应订单的数据。
 *
 * @param {string} orderNo - 驾驶订单的唯一标识符
 * @returns {Promise} 返回一个Promise对象，该对象包含服务器响应的数据
 */
export function driveOrderInfo(orderNo) {
    // 发送GET请求来获取驾驶订单信息
    return request({
        url: `/open/order/driveOrderInfo/${orderNo}`,
        method: 'GET',
    })
}

export function driveOrderPay(data) {
    return request({
        url: '/open/order/driveAliPay',
        method: 'POST',
        data
    })
}

export function driveOrderPayResult(orderNo) {
    return request({
        url: `/open/order/drivePayResult/${orderNo}`,
        method: 'GET',
    })
}

